import App from './App';
import {
    render,
    useEffect,
    createContext,
} from '@wordpress/element';
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import omit from 'lodash.omit';
import './style/main.scss';
import * as Sentry from '@sentry/react';
import config from './config.json';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ExtraErrorData } from '@sentry/integrations';
import ErrorComponent from './ErrorComponent';

export const ConfigContext = createContext(null);

const { apiEndpoint, fallback } = rangeHeadlessApi;

/**
 * Get Sentry DSN per project based on URL.
 * DSN key in config should match live URL.
 * All dev sites will go to 'range-headless-dev' project.
 * If no matching project found, will go to 'range-headless'
 * @returns {string}
 */
function getSentryDsn() {
    const url = location.hostname.split('.');

    if (url.includes('test') || url[1] === 'websitefirstlook') {
        return config.sentryConfigs['range-headless-dev'];
    }

    let domain = '';
    if (url[0] === 'www') {
        domain = url[1];
    } else {
        domain = url[0];
    }

    if (Object.keys(config.sentryConfigs).includes(domain)) {
        return config.sentryConfigs[domain];
    }

    return config.sentryConfigs['range-headless'];
}

/**
 * Shortcode data.
 */
let shortcodeData = {};

/**
 * Global config.
 */
let globalConfig = {};

const roots = document.querySelectorAll('[id^="range-headless-root-"]');
if (roots.length > 0) {
    roots.forEach((root) => {
        if (root.dataset.rangeheadlessGlobal === 'true') {
            const data = JSON.parse(root.dataset.rangeheadless);
            globalConfig = data.globalConfig;
            shortcodeData[root.id] = omit(data, 'globalConfig');
        } else {
            shortcodeData[root.id] = JSON.parse(root.dataset.rangeheadless);
        }
    });
}

const router = createBrowserRouter([
    {
        path: '/:viewName?/:locationSlug?/:categoryType?/:categoryName?/:specialCategoryName?',
        element: (
            <Sentry.ErrorBoundary
                fallback={(props) => <ErrorComponent {...props} />}
            >
                <ConfigContext.Provider value={globalConfig}>
                    <App shortcodeData={shortcodeData} fallback={fallback} />
                </ConfigContext.Provider>
            </Sentry.ErrorBoundary>
        ),
    },
]);

/**
 * Sentry logging
 */
Sentry.init({
    environment:
        process.env.NODE_ENV === 'development' ? 'development' : 'production',
    dsn: getSentryDsn(),
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useNavigationType,
                useLocation,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        ExtraErrorData(),
    ],
    tracePropagationTargets: [/.+\/wp-json\/.+/, apiEndpoint],
    tracesSampleRate: 0.2,
    sampleRate: 0.2,
    normalizeDepth: 4,
});

const queryClient = new QueryClient();

/**
 * React 18+
 */
if (createRoot) {
    if (roots.length > 0) {
        createRoot(roots[0]).render(
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={router} />
                </QueryClientProvider>
            </React.StrictMode>
        );
    }

    /**
     * Pre-React 18
     */
} else {
    if (roots.length > 0) {
        render(
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={router} />
                </QueryClientProvider>
            </React.StrictMode>,
            roots[0]
        );
    }
}
